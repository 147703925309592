import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, IBLoCInitialisable, renderBlocChild } from 'types/BLoCBase';
import {
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { $delete, $get, $getWithMeta, $post } from 'services/api';
import { ChatModalTypes } from './ChatModal.types';
import { UseFormReturn, useForm } from 'react-hook-form';
import { $fromHookForm } from 'utils/$fromHookForm';
import { getQueryParams } from 'utils/getQueryParams';
import { NumberParam } from 'hooks/useQueryParams';
import { pusher } from 'services/pusher';
import { clipboard } from 'utils/clipboard';
import { toast } from 'services/toast';
import { snackbar } from 'services/snackbar';
import { downloadFile } from 'utils/downloadFile';
import { MainModuleTypes } from 'modules/MainModule/MainModule.types';
import { getCustomerStore } from 'stores/customer.store';
import { ProjectsPageTypes } from 'modules/MainModule/pages/ProjectsPage/ProjectsPage.types';
import { navigate } from 'services/navigate';
import { auDataTemplatePersonaMapper } from 'utils/auDataTemplatePersonaMapper';

type State = {
  visible: boolean;
  selectedChatId?: number | null;
  selectedMessageId?: number | null;
  chats?: ChatModalTypes.Chat[];
  chatsMeta?: {
    count: number;
    nextPage?: number;
    perPage?: number;
  };
  chatsLoading?: boolean;
  loading?: boolean;
  selectedMessageHasNoResponses?: boolean;
  openedMobile: boolean;
  refetchDate?: Date;
  questions?: { id: string; cleanTitle: string; type: string }[] | null;
  summaries?: MainModuleTypes.Summary[] | null;
  chatMode: 'gen' | 'persona';
};

export const verifyUrlModule = () => {
  const path = window.location.pathname;
  const pathNameSplit = path.split('/');
  const module = pathNameSplit[1] || '';

  let route = '';

  if (path === '/home') route = 'home';
  else if (path === '/projects') route = 'projects';
  else if (module === 'projects' && pathNameSplit.length === 3) route = 'projects/id';
  else if (module === 'projects' && path.includes('ingestions')) route = 'projects/id/ingestions';
  else if (module === 'projects' && path.includes('exports')) route = 'projects/id/exports';
  else if (module === 'projects' && path.includes('questions')) route = 'projects/id/questions';
  else if (path === '/hey-yabble') route = 'hey-yabble';
  else if (path === '/hey-yabble/new') route = 'hey-yabble/new';
  else if (path.includes('hey-yabble/task-completed')) route = 'hey-yabble/task-completed';
  else if (path.includes('hey-yabble/task')) route = 'hey-yabble/task';
  else if (path.includes('hey-yabble/explore')) route = 'hey-yabble/explore';
  else if (path === '/stories') route = 'stories';
  else if (module === 'stories' && path.includes('edit')) route = 'stories/id/edit';
  else if (module === 'stories' && path.includes('view')) route = 'stories/id/view';
  else route = 'others';

  return route;
};
class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  private modalOnCloseSubscribers: (() => void)[] = [];
  public $selectedChatId = this.$getState('selectedChatId');
  public $selectedMessageId = this.$getState('selectedMessageId');
  public $projects = combineLatest([
    this.$getState('refetchDate'),
    this.$getState('chatMode').pipe(distinctUntilChanged()),
    getCustomerStore().$customerSlug,
  ]).pipe(
    filter(([d]) => !!d),
    switchMap(([_, mode, customerSlug]) =>
      $get<ProjectsPageTypes.Project[]>(
        `project-list-page/customers/${customerSlug}/all-consolidated-projects`
      ).pipe(map((projects) => (mode === 'persona' ? projects.filter((p) => p.type === 'va') : projects)))
    ),
    shareReplay()
  );

  public $visible = this.$getState('visible');
  public $openedMobile = this.$getState('openedMobile');
  public $loading = this.$getState('loading');
  public $projectId = $fromHookForm(this.newChatForm, 'projectId');
  public $questions = this.$getState('questions');
  public $questionId = $fromHookForm(this.newChatForm, 'questionId');
  public $summaries = this.$getState('summaries');
  public $summaryId = $fromHookForm(this.newChatForm, 'summaryId');
  public $chats = this.$getState('chats');
  public $chatsMeta = this.$getState('chatsMeta');
  public $chatsLoading = this.$getState('chatsLoading');
  public $selectedChat = combineLatest([this.$chats, this.$selectedChatId]).pipe(
    map(([cs, id]) => (id ? cs?.find((c) => c.id === id) : null))
  );
  public $thirdPanelEvents = new Subject<'close' | 'open'>();
  public $selectedMessageHasNoResponses = this.$getState('selectedMessageHasNoResponses');
  public $chatMode = this.$getState('chatMode');
  public $projectPersonas = combineLatest([this.$selectedChat, this.$chatMode]).pipe(
    filter(([selectedChat, chatMode]) => chatMode === 'persona' && !!selectedChat?.project.id),
    distinctUntilChanged((prev, curr) => prev[0]?.project.id === curr[0]?.project.id),
    switchMap(([selectedChat]) =>
      $get<MainModuleTypes.AugmentedDataTemplateApiResp>(
        `ad-project-templates/for-project/${selectedChat?.project.id}`
      ).pipe(map((augDataTemplate) => auDataTemplatePersonaMapper(augDataTemplate.personas)))
    ),
    startWith(null),
    shareReplay()
  );
  public $projectPersonaWithHighestWeighting = this.$projectPersonas.pipe(
    map(
      (personas) =>
        [...(personas || [])].sort((a, b) => b.distributionWeight - a.distributionWeight).at(0) || null
    ),
    distinctUntilChanged()
  );
  public $newChatFormProjectSelectedIsSummarization = combineLatest([this.$projectId, this.$projects]).pipe(
    map(([projectId, projects]) => projects?.find((p) => p.id === projectId)?.type === 'summarization'),
    distinctUntilChanged()
  );
  public $isSelectedChatFromSummarizationProject = this.$selectedChat.pipe(
    map((c) => !!c?.summarizationProject),
    distinctUntilChanged()
  );

  public $selectedProject = combineLatest([this.$projectId, this.$projects]).pipe(
    map(([projectId, projects]) => projects?.find((p) => p.id === projectId)),
    distinctUntilChanged((prev, curr) => prev?.id === curr?.id)
  );

  constructor(
    public newChatForm: UseFormReturn<ChatModalTypes.FormFields, any>,
    readonly forceChatMode?: State['chatMode'],
    public readonly loadChatAsWidget?: number,
    public readonly greetingMessage?: string
  ) {
    super({
      visible: typeof loadChatAsWidget === 'number',
      openedMobile: true,
      refetchDate: new Date(),
      chatMode: forceChatMode || 'gen',
      selectedChatId: loadChatAsWidget,
    });
  }

  public setChatMode = (mode: State['chatMode']) => {
    this.setStates({
      chatMode: mode,
      selectedChatId: null,
      chats: undefined,
      chatsMeta: undefined,
    });
    setTimeout(() => this.fetchChats());
  };

  public openChatOnMobile = (value: boolean) => this.setState('openedMobile', value);

  private markChatAsChecked = (chat: ChatModalTypes.Chat) => {
    return Object.assign({}, chat, {
      syncStatus: Object.assign({}, chat.syncStatus || {}, {
        status: 'checked',
      }),
    });
  };

  public onInit = () => {
    setTimeout(() => {
      this.addSub(
        pusher
          .$listen<{
            status: string;
            projectId: string | number;
            surveyQuestionId: string | null;
            summaryId: number;
            projectType: 'regular' | 'summarization';
          }>('.WeaviateStatusChanged')

          .subscribe((event) =>
            this.mutateState('chats', (chats) =>
              chats?.slice()?.map((c) => {
                if (event?.summaryId && c.summary?.id) {
                  return +c.summary?.id === +event.summaryId ? this.markChatAsChecked(c) : c;
                } else if (event.projectType === 'summarization') {
                  return c.summarizationProject?.id === event.projectId ? this.markChatAsChecked(c) : c;
                } else return c.project.id === event.projectId ? this.markChatAsChecked(c) : c;
              })
            )
          )
      );
    }, 1000);
    this.addSub(this.$visible.subscribe((v) => v && this.fetchChats()));
    this.addSub(
      this.$selectedChat
        .pipe(distinctUntilChanged((a, b) => a?.id === b?.id))
        .subscribe(
          (sc) =>
            sc &&
            sc?.syncStatus?.status !== 'checked' &&
            $post<{ syncing: boolean }>(`chats/${sc.id}/sync-data`).subscribe(
              ({ syncing }) =>
                sc &&
                !syncing &&
                this.mutateState('chats', (chats) =>
                  chats?.slice()?.map((c) => (c.id === sc.id ? this.markChatAsChecked(c) : c))
                )
            )
        )
    );
    this.addSub(
      this.$selectedProject
        .pipe(
          tap(() => {
            this.setState('questions', null);
            this.newChatForm.resetField('questionId');
          }),
          filter(
            (selectedProject) =>
              !!selectedProject?.id &&
              selectedProject.type !== 'summarization' &&
              this.currentState('chatMode') === 'gen'
          ),
          switchMap((selectedProject) =>
            $get<
              {
                id: string;
                cleanTitle: string;
                type: string;
              }[]
            >(`v3/projects/${selectedProject?.id}/questions-all`, {
              'filter[types]': 'text',
              'filter[with_responses]': 'true',
            }).pipe(first())
          )
        )
        .subscribe((questions) => this.setState('questions', questions))
    );
    this.addSub(
      this.$selectedProject
        .pipe(
          tap(() => {
            this.setState('summaries', null);
            this.newChatForm.resetField('summaryId');
          }),
          filter(
            (selectedProject) =>
              !!selectedProject?.id &&
              this.currentState('chatMode') === 'gen' &&
              selectedProject?.type === 'summarization'
          ),
          switchMap((selectedProject) =>
            $get<MainModuleTypes.Summary[]>(
              `summarization/projects/${selectedProject?.id}/all-summaries`
            ).pipe(first())
          )
        )
        .subscribe((summaries) => this.setState('summaries', summaries))
    );
  };

  public setIds = (projectId?: string | null, questionId?: string | null) =>
    this.newChatForm.reset({ projectId, questionId });

  public openModal = (params?: {
    projectId?: string | number | null;
    questionId?: string | null;
    summaryId?: number | null;
    mode?: 'gen' | 'persona';
  }) => {
    this.setChatMode(params?.mode || 'gen');
    if (params?.projectId)
      this.newChatForm.reset({
        projectId: params?.projectId,
        questionId: params?.questionId,
        summaryId: params?.summaryId,
      });
    this.setStates({ visible: true, refetchDate: new Date() });
  };
  public closeModal = () => {
    this.setState('visible', false);
    this.modalOnCloseSubscribers.forEach((s) => s());

    // DEVELOPER NOTES: if chat modal has been open with personaId query param
    // this query param needs to be removed when closing.
    // THIS IS NOT NEEDED FOR WORKSPACES EPIC (CE-9791)
    const params = getQueryParams({ personaId: NumberParam });
    if (params?.personaId) {
      const paramsAfter = new URLSearchParams(location.search);
      paramsAfter.delete('personaId');
      navigate(`${window.location.pathname}${paramsAfter ? `?${paramsAfter.toString()}` : ''}`);
    }
  };

  public sendThirdPanelEvent = (event: 'open' | 'close') => this.$thirdPanelEvents.next(event);

  public setSelectedChatId = (selectedChatId?: number | null) => {
    if (!selectedChatId)
      this.newChatForm.reset({
        projectId: null,
        questionId: null,
        summaryId: null,
      });
    this.setStates({ selectedChatId, selectedMessageId: null });
    this.openChatOnMobile(true);
  };

  public setSelectedMessageId = (id?: number | null, hasNoResponses?: boolean) => {
    this.sendThirdPanelEvent(id ? 'open' : 'close');
    this.setStates({
      selectedMessageId: id,
      selectedMessageHasNoResponses: hasNoResponses,
    });
  };

  public fetchChats = (loadMore?: boolean) => {
    if (this.currentState('visible') && !this.currentState('chatsLoading')) {
      this.setState('chatsLoading', true);
      this.addSub(
        getCustomerStore()
          .$customerId.pipe(
            distinctUntilChanged(),
            filter((customerId) => !!customerId),
            switchMap((customerId) =>
              $getWithMeta<ChatModalTypes.Chat[]>('chats', {
                customerId,
                chatType: this.currentState('chatMode'),
                perPage: window.innerHeight > 780 ? 20 : 10,
                ...(loadMore && {
                  page: this.currentState('chatsMeta')?.nextPage,
                }),
              }).pipe(finalize(() => this.setState('chatsLoading', false)))
            )
          )
          .subscribe((chatsWithMeta) => {
            const chats: ChatModalTypes.Chat[] = chatsWithMeta[0].map((c) => ({
              ...c,
              project: c.summarizationProject || c.project,
              question: c.summary || c.question,
            }));
            this.setState('chatsMeta', {
              count: chatsWithMeta[1].total,
              nextPage: chatsWithMeta[1].currentPage + 1,
              perPage: chatsWithMeta[1].perPage,
            });
            loadMore
              ? this.mutateState('chats', (prev) => [...(prev || []), ...chats])
              : this.setState('chats', chats);
          }),
        'fetchChats'
      );
    }
  };

  public handleNewPersonaChat = this.newChatForm?.handleSubmit((values) => {
    this.setState('loading', true);
    this.addSub(
      $post<any>('chats/for-regular-project', {
        projectId: values.projectId,
        chatType: 'persona',
      })
        .pipe(finalize(() => this.setState('loading', false)))
        .subscribe({
          next: (c) => {
            this.fetchChats();
            setTimeout(() => this.setSelectedChatId(c.id));
          },
          error: (err) => {
            snackbar({
              severity: 'error',
              label: err.message,
              life: 8000,
              closable: true,
            });
          },
        }),
      'handleNewPersonaChat'
    );
  });

  public handleNewChat = (
    projectId: string | number,
    isSummarization?: boolean,
    questionId?: string | null,
    summaryId?: number | null
  ) => {
    const questions = this.currentState('questions');
    const summaries = this.currentState('summaries');
    const questionSelected = questions?.find((q) => q.id === questionId);
    const summarySelected = summaries?.find((q) => q.id === summaryId);

    if (summarySelected && summarySelected.status !== 'summarization_completed') {
      snackbar({
        severity: 'error',
        label: `This ${
          questionSelected ? 'question' : 'summary'
        } has no data. Please ensure that the data has been successfully imported or summarized.`,
        life: 8000,
        closable: true,
      });
      return;
    }

    if (isSummarization && !summaries?.some((s) => s.status === 'summarization_completed')) {
      snackbar({
        severity: 'error',
        label:
          'This project has no data. Please ensure that the data has been successfully imported or summarized.',
        life: 8000,
        closable: true,
      });
      return;
    }

    this.setState('loading', true);
    this.addSub(
      $post<any>(`chats/${!isSummarization ? 'for-regular-project' : 'for-summarization-project'}`, {
        projectId: projectId,
        questionId: questionId,
        summaryId: summaryId,
        ...(!isSummarization && { chatType: this.currentState('chatMode') }),
      })
        .pipe(finalize(() => this.setState('loading', false)))
        .subscribe({
          next: (c) => {
            this.fetchChats();
            setTimeout(() => this.setSelectedChatId(c.id));
          },
          error: (err) => {
            snackbar({
              severity: 'error',
              label: err.message,
              life: 8000,
              closable: true,
            });
          },
        }),
      'handleNewChat'
    );
  };

  public touchChat = (chatId: number) =>
    this.mutateState('chats', (chats) =>
      chats?.map((c) => (c.id !== chatId ? c : Object.assign({}, c, { updatedAt: new Date() })))
    );

  public setProjectQuestionIds = (payload: {
    projectId?: string | null;
    questionId?: string | null;
    summaryId?: number | null;
    chatMode?: 'gen' | 'persona';
  }) => {
    this.newChatForm.reset({
      projectId: payload.projectId,
      questionId: payload.questionId,
      summaryId: payload.summaryId,
    });
    if (payload.chatMode) this.setChatMode(payload.chatMode);
  };

  public chatModalOnCloseSubscribe = (sub: () => void) => {
    this.modalOnCloseSubscribers.push(sub);
    return () => {
      this.modalOnCloseSubscribers.filter((s) => s !== sub);
    };
  };
  public copyDebugInfo = () => {
    this.$selectedChat.pipe(first()).subscribe((chat) => {
      const data = chat ? chat : this.currentState('chats');
      clipboard.copyTextToClipboard(JSON.stringify(data));
      toast({
        severity: 'success',
        summary: 'Exported to clipboard',
        detail: 'Use ctrl+v to paste it',
      });
    });
  };

  public exportChat = (chatId: ChatModalTypes.Chat['id']) => {
    snackbar({
      severity: 'info',
      label: 'Your download will begin shortly',
      life: 3000,
    });
    $get(`chats/${chatId}/export`, {}, { responseType: 'blob' }).subscribe((blob: any) => {
      this.setState('loading', false);
      downloadFile(blob, 'themes-export.csv');
    });
  };

  public deleteChat = (chatId: ChatModalTypes.Chat['id']) => {
    this.addSub(
      $delete(`chats/${chatId}`).subscribe({
        next: () => {
          const loadedChatsQtyAfterDeleting = (this.currentState('chats')?.length || 0) - 1;
          if (chatId === this.currentState('selectedChatId')) {
            this.setSelectedChatId(null);
          }
          this.mutateState('chats', (prev) => (prev || []).filter((c) => c.id !== chatId));
          this.mutateState(
            'chatsMeta',
            (prev) =>
              prev && {
                ...prev,
                count: prev.count - 1,
                nextPage: Math.ceil(loadedChatsQtyAfterDeleting / (prev.perPage || 10)) + 1,
              }
          );
          snackbar({
            label: 'Deleted chat successfully',
          });
        },
        error: (err) => {
          snackbar({
            label: err?.message || 'An error ocurred while trying to delete chat.',
            severity: 'error',
          });
        },
      }),
      'deleteChat'
    );
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useChatModalBLoC = () => useBLoC<BLoC>(Context);

export const ChatModalBLoC: React.FC<
  BLoCParams<BLoC, State> & {
    loadChatAsWidget?: number;
    forceChatMode?: 'persona' | 'gen';
    greetingMessage?: string;
  }
> = ({ loadChatAsWidget, forceChatMode, greetingMessage, children }) => {
  const newChatForm = useForm({
    defaultValues: new ChatModalTypes.FormFields(),
    mode: 'all',
  });
  const bloc = useInitBloc(() => new BLoC(newChatForm, forceChatMode, loadChatAsWidget, greetingMessage));
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
